<template>
  <div class="page-wrapper planAdd-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Assign Plan To Group</h1>
      </div>
      <div>
        <employer-group-plan-select-card-list :groupId="groupId" />
      </div>
    </div>
  </div>
</template>
<script>
import EmployerGroupPlanSelectCardList from "./plans/EmployerGroupPlanSelectCardList";
export default {
  name: "EmployerGroupPlans",
  components: {
    EmployerGroupPlanSelectCardList
  },
  data: () => ({
    groupId: null,
    breadcrumbItems: []
  }),
  methods: {
    getBreadCrumbs() {
      this.breadcrumbItems = [
        {
          title: "Employers & Groups",
          path: {
            name: "EmployerGroupList",
          },
          active: false,
        },
        {
          title: "View / Add Plans",
          active: true,
        },
      ];
    }
  },
  created() {
    this.groupId = parseInt(atob(this.$route.params.groupId));
    this.getBreadCrumbs();
  },
};
</script>
<style src="@/assets/css/employer-groups.css"></style>
